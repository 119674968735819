//
// Utility classes
// --------------------------------------------------

//
// Margin  and Padding
// --------------------------------------------------
/*** margin ***/
@margin-base:60px;
.mt-none{margin-top:0px;}
.mt-xxs{margin-top:(@margin-base * 0.25);}
.mt-xs{margin-top:(@margin-base * 0.5);}
.mt-s{margin-top:(@margin-base * 0.75);}
.mt-m{margin-top:@margin-base;}
.mt-l{margin-top:(@margin-base * 1.25);}
.mt-xl{margin-top:(@margin-base * 1.5);}
.mt-xxl{margin-top:(@margin-base * 2);}

.mb-none{margin-bottom:0px;}
.mb-xxs{margin-bottom:(@margin-base * 0.25);}
.mb-xs{margin-bottom:(@margin-base * 0.5);}
.mb-s{margin-bottom:(@margin-base * 0.75);}
.mb-m{margin-bottom:@margin-base;}
.mb-l{margin-bottom:(@margin-base * 1.25);}
.mb-xl{margin-bottom:(@margin-base * 1.5);}
.mb-xxl{margin-bottom:(@margin-base * 2);}

.ml-none{margin-left:0px;}
.ml-xxs{margin-left:(@margin-base * 0.25);}
.ml-xs{margin-left:(@margin-base * 0.5);}
.ml-s{margin-left:(@margin-base * 0.75);}
.ml-m{margin-left:@margin-base;}
.ml-l{margin-left:(@margin-base * 1.25);}
.ml-xl{margin-left:(@margin-base * 1.5);}
.ml-xxl{margin-left:(@margin-base * 2);}

.mr-none{margin-right:0px;}
.mr-xxs{margin-right:(@margin-base * 0.25);}
.mr-xs{margin-right:(@margin-base * 0.5);}
.mr-s{margin-right:(@margin-base * 0.75);}
.mr-m{margin-right:@margin-base;}
.mr-l{margin-right:(@margin-base * 1.25);}
.mr-xl{margin-right:(@margin-base * 1.5);}
.mr-xxl{margin-right:(@margin-base * 2);}

/*** padding ***/
@padding-base:60px;
.pt-none{padding-top:0px;}
.pt-xxs{padding-top:(@padding-base * 0.25);}
.pt-xs{padding-top:(@padding-base * 0.5);}
.pt-s{padding-top:(@padding-base * 0.75);}
.pt-m{padding-top:@padding-base;}
.pt-l{padding-top:(@padding-base * 1.25);}
.pt-xl{padding-top:(@padding-base * 1.5);}
.pt-xxl{padding-top:(@padding-base * 2);}

.pb-none{padding-bottom:0px!important;}
.pb-xxs{padding-bottom:(@padding-base * 0.25);}
.pb-xs{padding-bottom:(@padding-base * 0.5);}
.pb-s{padding-bottom:(@padding-base * 0.75);}
.pb-m{padding-bottom:@padding-base;}
.pb-l{padding-bottom:(@padding-base * 1.25);}
.pb-xl{padding-bottom:(@padding-base * 1.5);}
.pb-xxl{padding-bottom:(@padding-base * 2);}

.pl-none{padding-left:0px;}
.pl-xxs{padding-left:(@padding-base * 0.25);}
.pl-xs{padding-left:(@padding-base * 0.5);}
.pl-s{padding-left:(@padding-base * 0.75);}
.pl-m{padding-left:@padding-base;}
.pl-l{padding-left:(@padding-base * 1.25);}
.pl-xl{padding-left:(@padding-base * 1.5);}
.pl-xxl{padding-left:(@padding-base * 2);}

.pr-none{padding-right:0px;}
.pr-xxs{padding-right:(@padding-base * 0.25);}
.pr-xs{padding-right:(@padding-base * 0.5);}
.pr-s{padding-right:(@padding-base * 0.75);}
.pr-m{padding-right:@padding-base;}
.pr-l{padding-right:(@padding-base * 1.25);}
.pr-xl{padding-right:(@padding-base * 1.5);}
.pr-xxl{padding-right:(@padding-base * 2);}

@media screen and (max-width: @screen-sm-max) {
  @margin-base:30px;
  .mt-none{margin-top:0px;}
  .mt-xxs{margin-top:(@margin-base * 0.25);}
  .mt-xs{margin-top:(@margin-base * 0.5);}
  .mt-s{margin-top:(@margin-base * 0.75);}
  .mt-m{margin-top:@margin-base;}
  .mt-l{margin-top:(@margin-base * 1.25);}
  .mt-xl{margin-top:(@margin-base * 1.5);}
  .mt-xxl{margin-top:(@margin-base * 2);}

  .mb-none{margin-bottom:0px;}
  .mb-xxs{margin-bottom:(@margin-base * 0.25);}
  .mb-xs{margin-bottom:(@margin-base * 0.5);}
  .mb-s{margin-bottom:(@margin-base * 0.75);}
  .mb-m{margin-bottom:@margin-base;}
  .mb-l{margin-bottom:(@margin-base * 1.25);}
  .mb-xl{margin-bottom:(@margin-base * 1.5);}
  .mb-xxl{margin-bottom:(@margin-base * 2);}

  .ml-none{margin-left:0px;}
  .ml-xxs{margin-left:(@margin-base * 0.25);}
  .ml-xs{margin-left:(@margin-base * 0.5);}
  .ml-s{margin-left:(@margin-base * 0.75);}
  .ml-m{margin-left:@margin-base;}
  .ml-l{margin-left:(@margin-base * 1.25);}
  .ml-xl{margin-left:(@margin-base * 1.5);}
  .ml-xxl{margin-left:(@margin-base * 2);}

  .mr-none{margin-right:0px;}
  .mr-xxs{margin-right:(@margin-base * 0.25);}
  .mr-xs{margin-right:(@margin-base * 0.5);}
  .mr-s{margin-right:(@margin-base * 0.75);}
  .mr-m{margin-right:@margin-base;}
  .mr-l{margin-right:(@margin-base * 1.25);}
  .mr-xl{margin-right:(@margin-base * 1.5);}
  .mr-xxl{margin-right:(@margin-base * 2);}

  /*** padding ***/
  @padding-base:30px;
  .pt-none{padding-top:0px;}
  .pt-xxs{padding-top:(@padding-base * 0.25);}
  .pt-xs{padding-top:(@padding-base * 0.5);}
  .pt-s{padding-top:(@padding-base * 0.75);}
  .pt-m{padding-top:@padding-base;}
  .pt-l{padding-top:(@padding-base * 1.25);}
  .pt-xl{padding-top:(@padding-base * 1.5);}
  .pt-xxl{padding-top:(@padding-base * 2);}

  .pb-none{padding-bottom:0px;}
  .pb-xxs{padding-bottom:(@padding-base * 0.25);}
  .pb-xs{padding-bottom:(@padding-base * 0.5);}
  .pb-s{padding-bottom:(@padding-base * 0.75);}
  .pb-m{padding-bottom:@padding-base;}
  .pb-l{padding-bottom:(@padding-base * 1.25);}
  .pb-xl{padding-bottom:(@padding-base * 1.5);}
  .pb-xxl{padding-bottom:(@padding-base * 2);}

  .pl-none{padding-left:0px;}
  .pl-xxs{padding-left:(@padding-base * 0.25);}
  .pl-xs{padding-left:(@padding-base * 0.5);}
  .pl-s{padding-left:(@padding-base * 0.75);}
  .pl-m{padding-left:@padding-base;}
  .pl-l{padding-left:(@padding-base * 1.25);}
  .pl-xl{padding-left:(@padding-base * 1.5);}
  .pl-xxl{padding-left:(@padding-base * 2);}

  .pr-none{padding-right:0px;}
  .pr-xxs{padding-right:(@padding-base * 0.25);}
  .pr-xs{padding-right:(@padding-base * 0.5);}
  .pr-s{padding-right:(@padding-base * 0.75);}
  .pr-m{padding-right:@padding-base;}
  .pr-l{padding-right:(@padding-base * 1.25);}
  .pr-xl{padding-right:(@padding-base * 1.5);}
  .pr-xxl{padding-right:(@padding-base * 2);}
}


// Floats
// -------------------------

.clearfix {
  .clearfix();
}
.center-block {
  .center-block();
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}


// Toggling content
// -------------------------

// Note: Deprecated .hide in favor of .hidden or .sr-only (as appropriate) in v3.0.1
.hide {
  display: none !important;
}
.show {
  display: block !important;
}
.invisible {
  visibility: hidden;
}
.text-hide {
  .text-hide();
}


// Hide from screenreaders and browsers
//
// Credit: HTML5 Boilerplate

.hidden {
  display: none !important;
  visibility: hidden !important;
}


// For Affix plugin
// -------------------------

.affix {
  position: fixed;
}
